<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";
import appConfig from "@/app.config";

import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Korean} from "flatpickr/dist/l10n/ko.js"
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

/**
 * Basic Elements component
 */
export default {
  page: {
    title: "쿠폰발급",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      title: "쿠폰발급",
      items: [
        {
          text: "Forms",
        },
        {
          text: "Basic Elements",
          active: true,
        },
      ],
      market_name: "",
      deviceid: "9876543210",
      method: "S",               //S or M or DS S: Single, M:Multi, DS:Download Single
      type: "price",                 //rate(정률) or price(정액) or product(상품) or gift(증정)
      product_name: "",         //증정상품명
      value: "",                //수치(금액 또는 퍼센트)
      comment: "",              //상품명
      condition: "",            //사용조건
      today: "",
      start_date: "",           //시작일
      end_date: "",             //종료일
      place_used: "online",           //사용처 online or offline or both
      target: "all",               //all(전체) or mymart(마이마트)
      key: "",
      radius: "",
      config: {
        enableTime: false,
        minDate: 'today',
        dateFormat: "Y-m-d",
        locale: Korean,
      },
    };
  },
  methods: {
    //오늘 날짜 가져오기
    setNowTimes() {
      this.today = new Date().toJSON().slice(0, 10)
      this.start_date = this.today
      this.end_date = this.today
    },
    //마켓정보설정
    setMarketInfo() {
      this.market_name = this.logged_info.mart_name
      this.key = this.logged_info.mart_srl
      this.radius = this.logged_info.radius
    },
    //RadioButton에서 발행대상값 설정
    setTargetValue(event) {
      if (event.target.value == "all") {
        this.target = "all"
      } else if (event.target.value == "mymart") {
        this.target = "mymart"
      }
      console.log(this.target)
    },
    //RadioButton에서 사용처 값 설정
    setUsageValue(event) {
      if (event.target.value == "online") {
        this.place_used = "online"
        this.comment = ""
      } else if (event.target.value == "offline") {
        this.place_used = "offline"
      } else if (event.target.value == "both") {
        this.place_used = "both"
        this.comment = ""
      }
      console.log(this.place_used)
    },
    //RadioButton에서 유형 값 설정
    setTypeValue(event) {
      if (event.target.value == "price") {
        this.type = "price"
        this.product_name = ""
      } else if (event.target.value == "gift") {
        this.type = "gift"
        this.value = ""
      }
      console.log(this.type)
    },
    //RadioButton에서 종류 값 설정
    setMethodValue(event) {
      if (event.target.value == "S") {
        this.method = "S"
      } else if (event.target.value == "D") {
        this.method = "D"
      } else if (event.target.value == "DS") {
        this.method = "DS"
      }
      console.log(this.method)
    },
    //쿠폰 발행
    issueCoupon() {
      //validation check
      if (this.method === "DS") {
        if (this.type === "gift") {
          if (this.product_name === "") {
            this.$bvModal.msgBoxOk('증정상품명을 입력해주세요.', msgModalOptCenter)
            return
          }
        } else {
          if (this.value === "") {
            this.$bvModal.msgBoxOk('할인금액을 입력해주세요.', msgModalOptCenter)
            return
          }
        }
      } else {
        if (this.value === "") {
          this.$bvModal.msgBoxOk('할인금액을 입력해주세요.', msgModalOptCenter)
          return
        }
      }
      if (this.condition === "") {
        this.$bvModal.msgBoxOk('사용조건 금액을 입력해주세요.', msgModalOptCenter)
        return
      }
      if (this.start_date === "") {
        this.$bvModal.msgBoxOk('쿠폰사용기간(시작일)을 입력해주세요.', msgModalOptCenter)
        return
      }
      if (this.end_date === "") {
        this.$bvModal.msgBoxOk('쿠폰사용기간(종료일)을 입력해주세요.', msgModalOptCenter)
        return
      }
      if (this.start_date.replaceAll("-", "") > this.end_date.replaceAll("-", "")) {
        this.$bvModal.msgBoxOk('쿠폰사용기간 시작일과 종료일을 확인해주세요.', msgModalOptCenter)
        return
      }
      if (this.start_date.replaceAll("-", "") < this.today.replaceAll("-", "")) {
        this.$bvModal.msgBoxOk('쿠폰사용기간 시작일이 오늘보다 이전입니다. 다시 확인해주세요.', msgModalOptCenter)
        return
      }
      if (this.end_date.replaceAll("-", "") < this.today.replaceAll("-", "")) {
        this.$bvModal.msgBoxOk('쿠폰사용기간 종료일이 오늘보다 이전입니다. 다시 확인해주세요.', msgModalOptCenter)
        return
      }

      //쿠폰발행 확인 후 발행
      this.$bvModal.msgBoxConfirm(`쿠폰을 발행하시겠습니까?\n※ 쿠폰은 발행 후 수정이 불가능합니다.`, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              const fd = new FormData()
              fd.append("market_name", this.market_name)
              fd.append("deviceid", this.deviceid)
              fd.append("method", this.method)
              fd.append("type", this.type)
              fd.append("product_name", this.product_name)
              fd.append("comment", this.comment)
              fd.append("value", this.value)
              fd.append("condition", this.condition)
              fd.append("start_date", this.start_date)
              fd.append("end_date", this.end_date)
              fd.append("place_used", this.place_used)
              fd.append("target", this.target)
              fd.append("key", this.key)
              axios.post(`/api/coupon/market/${this.logged_info.mart_srl}`, fd)
                  .then((response) => {
                    console.log(response.data)
                    if (response.data.status === "success") {
                      this.pageToCouponList()
                    } else {
                      this.$bvModal.msgBoxOk('쿠폰발급에 실패하였습니다. \n재시도 후 동일한 문제가 발생되면 본사로 문의바랍니다.', msgModalOptCenter)
                      return
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
            }
          })
    },
    //해당행사 이미지 관리 페이지로 이동
    pageToCouponList: function () {
      //console.log(leaflet_name + " ,   " + leaflet_srl + " ,   " + s_date  + " ,   " + e_date)
      this.$router.push({
        name: 'couponlist'
      });
    },
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
  },
  mounted() {
    this.setMarketInfo()
    this.setNowTimes()

  },
  computed: {
    ...mapState(loggedInfoObject)
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-xl-8 col-md-8">
        <div class="card">
          <div
              class="card-header justify-content-between d-flex align-items-center"
          >
            <h4 class="card-title">한번 발행된 쿠폰은 내용이 수정이 되지 않습니다. </h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="row">
              <div class="col-xl-10">
                <div class="mb-3 row">
                  <label
                      class="col-md-2 col-form-label"
                  >마켓명</label
                  >
                  <div class="col-md-10">
                    <label
                        class="col-md-6 col-form-label"
                    >{{ market_name }}</label
                    >
                  </div>
                </div>

                <div class="mb-3 row">
                  <label
                      class="col-md-2 col-form-label"
                  >대상자</label
                  >
                  <div class="col-md-10 col-form-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="targetRadios"
                        id="targetRadios1"
                        @change="setTargetValue($event)"
                        value="all"
                        style="margin-left: 2px"
                        checked
                    />
                    <label class="form-check-label" for="targetRadios1" style="margin-left: 6px">
                      전체회원(마켓주변 {{ radius }}km이내의 모든 회원들과 마이마켓찜을 한 회원들)
                    </label>
                  </div>
                  <label
                      class="col-md-2 col-form-label"
                  ></label
                  >
                  <div class="col-md-10 col-form-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="targetRadios"
                        id="targetRadios2"
                        @change="setTargetValue($event)"
                        value="mymart"
                        style="margin-left: 2px"
                    />
                    <label class="form-check-label" for="targetRadios2" style="margin-left: 6px">
                      마이마켓회원 (마이마켓찜을 한 회원들)
                    </label>
                  </div>
                </div>
                <!-- end row -->

                <div class="mb-3 row">
                  <label
                      class="col-md-2 col-form-label"
                  >사용처</label
                  >
                  <div class="col-md-10 col-form-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="radiosUsage"
                        id="radiosUsage1"
                        @change="setUsageValue($event)"
                        value="online"
                        style="margin-left: 2px"
                        checked
                    />
                    <label class="form-check-label" for="radiosUsage1" style="margin-left: 6px">
                      앱전용
                    </label>

                    <input
                        class="form-check-input"
                        type="radio"
                        name="radiosUsage"
                        id="radiosUsage2"
                        @change="setUsageValue($event)"
                        value="offline"
                        style="margin-left: 20px"
                    />
                    <label class="form-check-label" for="radiosUsage2" style="margin-left: 6px">
                      매장전용
                    </label>

                    <input
                        class="form-check-input"
                        type="radio"
                        name="radiosUsage"
                        id="radiosUsage3"
                        @change="setUsageValue($event)"
                        value="both"
                        style="margin-left: 20px"
                    />
                    <label class="form-check-label" for="radiosUsage3" style="margin-left: 6px">
                      앱/매장겸용
                    </label>
                  </div>
                </div>
                <!-- end row -->

                <div class="mb-3 row">
                  <label
                      class="col-md-2 col-form-label"
                  >쿠폰종류</label
                  >
                  <div class="col-md-10 col-form-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="methodRadios"
                        id="methodRadios1"
                        @change="setMethodValue($event)"
                        value="S"
                        style="margin-left: 2px"
                        checked
                    />
                    <label class="form-check-label" for="methodRadios1" style="margin-left: 6px">
                      일회성(알림): 푸시알람으로 소비자에게 알립니다.
                    </label>
                  </div>
                  <label
                      class="col-md-2 col-form-label"
                  ></label
                  >
                  <div class="col-md-10 col-form-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="methodRadios"
                        id="methodRadios2"
                        @change="setMethodValue($event)"
                        value="D"
                        style="margin-left: 2px"
                    />
                    <label class="form-check-label" for="methodRadios2" style="margin-left: 6px">
                      다운로드(다회성)
                    </label>
                  </div>
                  <label
                      class="col-md-2 col-form-label"
                  ></label
                  >
                  <div class="col-md-10 col-form-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="methodRadios"
                        id="methodRadios3"
                        @change="setMethodValue($event)"
                        value="DS"
                        style="margin-left: 2px"
                    />
                    <label class="form-check-label" for="methodRadios3" style="margin-left: 6px">
                      다운로드(일회성): 증정쿠폰이 발행가능합니다.
                    </label>
                  </div>
                </div>
                <!-- end row -->

                <!-- end row -->

                <div class="mb-3 row">
                  <label
                      class="col-md-2 col-form-label"
                  >쿠폰유형</label
                  >
                  <div class="col-md-10 col-form-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="radiosType"
                        id="radiosType1"
                        @change="setTypeValue($event)"
                        value="price"
                        style="margin-left: 2px"

                        checked
                    />
                    <label class="form-check-label" for="radiosType1" style="margin-left: 6px">
                      정액할인
                    </label>

                    <input
                        class="form-check-input"
                        type="radio"
                        name="radiosType"
                        id="radiosType2"
                        @change="setTypeValue($event)"
                        value="gift"
                        style="margin-left: 20px"
                        v-if="this.method === 'DS'"
                    />
                    <label class="form-check-label" for="radiosType2" style="margin-left: 6px"
                           v-if="this.method === 'DS'">
                      증정
                    </label>


                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
            <div class="col-xl-10 col-md-8">
              <div>
                <div class="mb-3 row" v-if="this.type === 'price'">
                  <label
                      for="priceValue"
                      class="col-md-2 col-form-label"
                  >정액할인 금액</label
                  >
                  <div class="col-md-4">
                    <input
                        v-model="value"
                        class="form-control"
                        type="number"
                        id="priceValue"
                        onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                        placeholder="할인금액을 입력해주세요."
                    />
                  </div>
                  <div class="col-md-2">
                    <label style="padding-top: 8px">원</label
                    >
                  </div>
                </div>
                <!-- end row -->
                <div class="mb-3 row" v-if="this.type === 'gift'">
                  <label
                      for="gift_product_name"
                      class="col-md-2 col-form-label"
                  >증정품</label
                  >
                  <div class="col-md-4">
                    <input
                        v-model="product_name"
                        class="form-control"
                        type="text"
                        value="딸기"
                        id="gift_product_name"
                    />
                  </div>
                </div>
                <!-- end row -->
                <div class="mb-3 row">
                  <label
                      class="col-md-2 col-form-label"
                  >사용기간 (시작일)</label
                  >
                  <div class="col-md-4">

                    <flat-pickr
                        class="form-control"
                        v-model="start_date"
                        :config="config"
                        id="s_date">
                    </flat-pickr>
                  </div>
                </div>
                <!-- end row -->

                <div class="mb-3 row">
                  <label
                      class="col-md-2 col-form-label"
                  >사용기간 (종료일)</label
                  >
                  <div class="col-md-4">
                    <flat-pickr
                        class="form-control"
                        v-model="end_date"
                        :config="config"
                        id="e_date">
                    </flat-pickr>
                  </div>
                </div>
                <!-- end row -->
                <div class="mb-3 row">
                  <label for="condition" class="col-md-2 col-form-label"
                  >사용조건</label
                  >
                  <div class="col-md-4">
                    <input
                        v-model="condition"
                        class="form-control"
                        type="number"
                        value=""
                        id="condition"
                        onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                        placeholder="금액을 입력해주세요."
                    />
                  </div>
                  <div class="col-md-2">
                    <label style="padding-top: 8px"> 원 이상 구매시</label
                    >
                  </div>
                </div>
                <!-- end row -->
                <div class="mb-3 row" v-if="place_used === 'offline'">
                  <label for="comment" class="col-md-2 col-form-label"
                  >한줄글</label
                  >
                  <div class="col-md-8">
                    <input
                        v-model="comment"
                        class="form-control"
                        type="text"
                        value=""
                        id="comment"
                        maxlength="30"
                        placeholder="매장전용쿠폰에서만 사용할 수 있는 옵션입니다. 미 입력시 공백처리됩니다."
                    />
                  </div>
                </div>
                <!-- end row -->
                <div class="col-xl-1 col-md-1">
                  <label
                      class="col-md-2 col-form-label"
                  ></label
                  >
                </div>
                <div class="col-xl-1 col-md-1">
                  <b-button @click="issueCoupon" variant="primary"
                  >발급
                  </b-button
                  >
                </div>

              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

  </Layout>
</template>
